import './monthlyProcessingResponse.css';
import {Container, Row, Col, Stack, OverlayTrigger, Button, Popover} from 'react-bootstrap'

export default function MontlyProcessingResponse(props) {

    const popover = (reportInfo, fileStatus) => (
        <Popover id="popover-basic">
          <Popover.Header as="h3">{fileStatus === "unproccessed" ? "Report folder name" : "Report file name"}</Popover.Header>
          <Popover.Body>
            {fileStatus === "unproccessed" ? reportInfo.inputFileName.slice(0,-1) : reportInfo.outputFileName}
          </Popover.Body>
        </Popover>
      );
    const listRenderer = (reportInfo, fileStatus) => {
        console.log(reportInfo)
            return(
                        <OverlayTrigger trigger="click" placement="right" overlay={popover(reportInfo, fileStatus)}>
                              <Button variant="warning" className="button-details">{reportInfo.dsp}</Button>
                        </OverlayTrigger>
            )
    }
    
    if ( props.data.isError ){
        console.log("arrived")
        console.log(props)
        return(
            <div className="response error">
                <b>{props.data.errorMessage}</b>
            </div>
            )
    } if ( props.data.length > 0 ) {

        console.log("props")
        console.log(props.data)
    
        const unproccessedFiles = props.data.filter(r => r.inputFileExists)
        console.log("unprocessed")
        console.log(unproccessedFiles)
        const proccessedFiles = props.data.filter(r => r.outputFileExists)
        console.log("processed")
        console.log(proccessedFiles)
        
        return(
            <Container>
                <Row>
                {<h1>Successfully Retrieved data for  {props.month} - {props.year} </h1>}
                    <Row>
                        <Col>
                            <h2>Unprocessed files</h2>
                            <Stack gap={unproccessedFiles.length}>
                                {unproccessedFiles.map(r => (r.inputFileExists) && listRenderer(r, "unproccessed"))}
                            </Stack>
                        </Col>
                        <Col>
                        <h2>Processed files</h2>
                        <Stack gap={proccessedFiles.length}>
                                {proccessedFiles.map(r => (r.outputFileExists) && listRenderer(r, "proccessed"))}
                            </Stack>
                        </Col>
                    </Row>
                </Row>
            </Container>
        )
    }
    else{
        console.log(props)
        return(<div className="spinner-border text-primary" role="status">
        <span className="sr-only"></span>
      </div>)
    }

}