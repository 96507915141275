import './individualResponse.css'
import Legend from '../legend'
import { ListGroup } from 'react-bootstrap'

export default function IndividualResponse(props) {
    const getStatus = () => {
    if (!props.isComplete){
        return "danger"
    }
    if (props.status === "success"){
        return "success"
    }
    if (props.status === "warning"){
        return "warning"
    }
    if(props.status === "error"){
        return "danger"
    }
    }

    if(props.dspName ){
        return(
            <div>
                <Legend />
                <ListGroup horizontal="sm" className="my-2" key="1">
                    <ListGroup.Item variant={getStatus()}><strong>DSP name:</strong> {props ? props.dspName : ""}</ListGroup.Item>
                    <ListGroup.Item variant={getStatus()}>Lines processed: {props ? props.totalLinesProcessed : ""}</ListGroup.Item>
                    <ListGroup.Item variant={getStatus()}>Error lines: {props ? props.errorLines : ""}</ListGroup.Item>
                    <ListGroup.Item variant={getStatus()}>Output file: {props ? props.outputFile : ""}</ListGroup.Item>
                    <ListGroup.Item variant={getStatus()}>Original Report Revenue: {props ? props.grossRevenue.toFixed(2)+"$" : ""}</ListGroup.Item>
                    <ListGroup.Item variant={getStatus()}>Album Sales Revenue: {props ? props.revenue.toFixed(2)+"$" : ""}</ListGroup.Item>
                    <ListGroup.Item variant={getStatus()}>Last month Revenue: {props ? props.lastMonthRevenue.toFixed(2)+"$" : ""}</ListGroup.Item>
                </ListGroup>
            </div>
        )
    } if ( props.isError ){
        console.log("arrived")
        console.log(props)
        return(
            <div className="response error">
                <b>{props.errorMessage}</b>
            </div>
            )
    }
    else{
        console.log(props)
        return(<div className="spinner-border text-primary" role="status">
        <span className="sr-only"></span>
      </div>)
    }
}

