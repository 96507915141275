import { useState } from "react"
import Cookies from 'js-cookie'
import {Container, Row, Col} from 'react-bootstrap'
import { FcCancel } from "react-icons/fc";

export default function ReportDefects() {
    const [submitData, setSubmitData] = useState({})
    const [availableResponse, setAvailableResponse] = useState([])    
    const [availableResponseVisibility, setAvailableResponseVisibility] = useState(false)
    const [spinnerVisibility, setSpinnerVisibility] = useState(false)

    const rowRenderer = (r) => {
        console.log(r)
        return(
            <Row className="datarow" key="1" >
                <Col>{r.reportFilename}</Col>
                <Col>{r.amount}</Col>
            </Row>
        )
    }


    const handleSubmitReportsDefects = (e)=>  {
        e.preventDefault()
        let cookieValue = Cookies.get('ReportHubAuth')
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/etls/defects?Month=${submitData.month}&Year=${submitData.year}`, {headers: {
            'Authorization': `Bearer ${cookieValue}`
          }})
        .then(res => {
            if (res.ok) {
                return res.json()
            } else {
                if (res.status === 400){
                    throw new Error(`Status code: ${res.status}. Please double check the input values`)
                }if (res.status === 404){
                    throw new Error(`Status code: ${res.status}. Report file not found on GCS`)
                }if (res.status === 401){
                    throw new Error(`Status code: ${res.status}. Unauthorized`)
                } else {
                    throw new Error(`Status code: ${res.status}.`)
                }
            }
        })
        .then(data =>{
            console.log("Response is :")
            console.log(data)
            const resp = {isError: false, data: data}
            setSpinnerVisibility(false)
            setAvailableResponse(resp)
            setAvailableResponseVisibility(true)
        }).catch(err => {
            let data = {}
            data.errorMessage = err.toString()
            data.isError = true
            setSpinnerVisibility(false)
            setAvailableResponse(data)
            setAvailableResponseVisibility(true)
        })
        setSpinnerVisibility(true)

    }

    const handleChange = (e) =>{
        console.log(submitData)
        if(e.target.name === "year"){
            submitData.year = e.target.value;
            console.log("year is changed")
        }
        if(e.target.name === "month"){
            submitData.month = e.target.value;
            console.log("month is changed")
        }
        setSubmitData(submitData)
    }

    return(
        <div>
            <form className="row g-3 needs-validation" noValidate>
                <div className="col-md-3">
                    <label className="form-label">Year</label>
                    <select name="year" className="form-select" value={submitData.year} onChange={handleChange}>
                    <option selected disabled value="">Choose...</option>
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>

                    </select>
                </div>
                <div className="col-md-3">
                    <label for="validationCustom04" className="form-label">Months</label>
                    <select name="month" className="form-select" value={submitData.month} onChange={handleChange}>
                    <option selected disabled value="">Choose...</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                    </select>
                </div>
                <div className="col-12">
                    <button className="btn btn-primary" onClick={handleSubmitReportsDefects} type="submit">Fetch report defects</button>
                </div>
                <p></p>
            </form>

                { spinnerVisibility && <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only"></span>
                                        </div>}

                { availableResponseVisibility && <div>
                                    {availableResponse.isError && <h6>{availableResponse.errorMessage} <FcCancel></FcCancel> </h6>}
                                    {!availableResponse.isError && 
                <div>
                    <Container>
                        <Row style={{width: '100%'}}>
                        {<h1>Reports with 0 count and revenue for {submitData.year} - {submitData.month} </h1>}
                            <Row className="headerrow">
                                <Col><strong>Report filename</strong></Col>
                                <Col><strong>Revenue ($)</strong></Col>
                            </Row>
                            <div style={{margin: '10px'}}>{availableResponse.data.reportsWithZeroCountAndRevenue.map(r => rowRenderer(r))}</div>
                            
                        </Row>
                        <Row style={{width: '100%'}}>
                        {<h1>Reports with negative balance for {submitData.year} - {submitData.month} </h1>}
                            <Row className="headerrow">
                                <Col><strong>Report filename</strong></Col>
                                <Col><strong>Revenue ($)</strong></Col>
                            </Row>
                        <div style={{margin: '10px'}}>{availableResponse.data.reportsWithNegativeBalances.map(r => rowRenderer(r))}</div>    
                        </Row>
                    </Container>
                    <p></p>
                </div>

                }</div>
                }
    </div>)
}