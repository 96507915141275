import {Spinner} from 'react-bootstrap'
import { useEffect } from 'react'
import Cookies from 'js-cookie'
import { MdDoneOutline } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";



export default function FeedBackTab(props){

    const getFeedback = async () => {
        let cookieValue = Cookies.get('ReportHubAuth')
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/v1/etls/feedback/${props.feedbackId}`, { headers: 
            {'Authorization': `Bearer ${cookieValue}`}});
        const data =  await res.json()
        return data
    }

    useEffect( () => {
        const intervalId = setInterval( async () =>{
                    const fb = await getFeedback()
                    props.setFeedback(fb) 
                
        }, 1*3000)

        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <div>
            <div>Live feedback is enabled...</div>
            {props.feedback.map(d => 
                <div>{d.dspName} {d.isComplete ? <MdDoneOutline /> : <AiOutlineClose />}</div>
            )}
            <Spinner animation="grow" variant="primary" />
        </div>
    )
}