import React from "react";
import { Route } from "react-router-dom";
import Cookies from 'js-cookie'


function ProtectedRoute({ component: Component, ...restOfProps }) {
    const isAuthenticated = Cookies.get('ReportHubAuth')
    console.log("this", isAuthenticated);

    return (
        <Route
        {...restOfProps}
        render={(props) =>
            isAuthenticated ? <Component {...props} /> : <h1>Not authenticated</h1>
        }
        />
    );
}

export default ProtectedRoute;