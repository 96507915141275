import './App.css';
import Header from  './header';
import MainApp from './components/main-app';
import { Fragment } from 'react';

const pingHealthcheck = async () => {
  while(true){
      await sleep(1000 * 60).then( () => {
          fetch(`${process.env.REACT_APP_BASE_URL}/healthcheck`)
      });
  }
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve,ms));
}


function App() {

  pingHealthcheck();

  return (
    <Fragment>
      <Header />
      <MainApp />
    </Fragment>
  );
}

export default App;
