import './periodicSummaryResponse.css';
import {Container, Row, Col} from 'react-bootstrap'
import {CSVLink} from 'react-csv';

export default function PeriodicSummaryResponse(props) {

    const numberFormat = (value) =>
        new Intl.NumberFormat('sv-SE', {
            useGrouping: false
        }).format(value);

    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-EU', {
            style: 'currency',
            currency: 'SEK',
            separator: ',',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);

    const rowRenderer = (r) => {
        console.log(r)
        let amount = parseFloat(r.amount)

        return(
            <Row className="datarow" key={r.vatNumber} >
                <Col><strong>{r.vatNumber}</strong></Col>
                <Col>{currencyFormat(amount)}</Col>
            </Row>
        )
    }

    const csvReport = {
        data: [[ "SKV574008" ], ["SE556752959801", props.year.slice(-2) + '-' + props.quarter, "Hannah Lundberg", "0705731211"]],
        separator: ';',
        enclosingCharacter: '',
        filename: 'PeriodicSummary_' + props.year + '_Q' + props.quarter + '.csv'
    }

    function setFileData(rows) {
        rows.map(r => 
            csvReport.data.push([r.vatNumber, '', '', numberFormat(r.amount)])
            )
    }

    if ( props.data.isError ){
        console.log("Error. props:")
        console.log(props)
        return(
            <div className="response error">
                <b>{props.data.errorMessage}</b>
            </div>
            )
    }
    
    if ( props.data.rows ) {
        setFileData(props.data.rows)
    
        return(
            <Container>
                <Row style={{width: '110%'}}>
                    {<h1>Periodic summary for {props.data.period}</h1>}
                    {<div>Exchange-rate USD-&gt;SEK: {props.data.exchangeRate}<div style={{textAlign: 'right'}}><CSVLink {...csvReport} className="btn btn-primary">Download file</CSVLink></div></div>}
                    <Row className="headerrow">
                        <Col><strong>Vat-number</strong></Col>
                        <Col><strong>Accumulated amount</strong></Col>
                    </Row>
                    {props.data.rows.map(r => rowRenderer(r))}
                </Row>
                <br/>
            </Container>
        )
    }
    else{
        return(<div className="spinner-border text-primary" role="status"><span className="sr-only"></span></div>)
    }
}