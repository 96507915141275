import Routing from './routing'
import './main-app.css'

export default function MainApp(){

    return (
    <div className="container">
        <div className="row">
            <Routing />
          </div>
    </div>
    )
}