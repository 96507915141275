import { ListGroup, Col } from "react-bootstrap"
import './legend.css'
export default function Legend(){
    return (
        <div className="legend">
            <ListGroup horizontal="sm" className="my-2" key="1">
                <Col md={{ span: 1, offset: 11 }}>
                    <ListGroup.Item variant="danger">Error</ListGroup.Item>
                    <ListGroup.Item variant="warning">Warning</ListGroup.Item>
                    <ListGroup.Item variant="success">Success</ListGroup.Item>
                </Col>
            </ListGroup>
        </div>
    )
}