import { useState } from "react"
import Cookies from 'js-cookie'
import { FcOk, FcCancel } from "react-icons/fc";
import {Container, Row, ListGroup, Col} from 'react-bootstrap'



export default function Result(){
    const [identifier, setIdentifier] = useState("")

    const [response, setResponse] = useState({})
    const [reponseArrived, setResponseArrived] = useState(false)

    const getStatus = (status) => {
        if (status === "Completed"){
            return "success"
        }
        if (status === "Failed"){
            return "danger"
        }
        if (status === "Started"){
            return "info"
        }
    }

    const handleSubmit = (e)=>  {
        e.preventDefault()
        let cookieValue = Cookies.get('ReportHubAuth')
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/report-automation/process-result?processGuid=${identifier}`, 
        {
            headers: {
            'Authorization': `Bearer ${cookieValue}`
          }})
        .then(res => {
            if (res.ok) {
                return res.json()
            } else {
                if (res.status === 400){
                    throw new Error(`Status code: ${res.status}. Please double check the input values`)
                }if (res.status === 404){
                    throw new Error(`Status code: ${res.status}.`)
                }if (res.status === 401){
                    throw new Error(`Status code: ${res.status}. Unauthorized`)
                } else {
                    throw new Error(`Status code: ${res.status}.`)
                }
            }
        })
        .then(data =>{
            const resp = {isError: false, content: data}
            console.log(resp)
            setResponse(resp)
            setResponseArrived(true) 
        }).catch(err => {
            let resp = {}
            resp.errorMessage = err.toString() + " Please check the identifier provided."
            resp.isError = true
            setResponse(resp)
            setResponseArrived(true) 
        })
    }

    let counter = 0
    return(
        <div>
            <p></p>
            <form className="row g-3 needs-validation" noValidate>
                <div className="col-md-3">
                    <label className="form-label">Enter single process identifier: </label>
                    <input value={identifier} onInput={e => setIdentifier(e.target.value)}/>
                </div>
                <div className="col-12">
                    <button className="btn btn-primary" onClick={handleSubmit} type="submit">Get results</button>
                </div>
            </form>
            <p></p>
      {
          reponseArrived && <div>
                           
                {response.isError && <h6>{response.errorMessage} <FcCancel></FcCancel> </h6>}
                {!response.isError &&  
                                        <div>
                                            {response.content.isTrialRun ? <h3>OBS: This result obtained in Trial Run</h3> : ""}
                                            <ListGroup horizontal="sm" className="my-2" key={counter++}>
                                                <ListGroup.Item variant={getStatus(response.content.status)}>Ingestion Guid: {response.content.ingestionGuid ? response.content.ingestionGuid : "Unknown"}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(response.content.status)}>Process Guid: {response.content.processGuid ? response.content.processGuid : "Unknown"}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(response.content.status)}>Filelink: <a href={response.content.fileLink ? response.content.fileLink : ""}>Link</a></ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(response.content.status)}>Filename: {response.content.filename ? response.content.filename : ""}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(response.content.status)}>Error Message: {response.content.errorMessage ? response.content.errorMessage : "None"}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(response.content.status)}>Number Of Sales: {response.content.numberOfSales ? response.content.numberOfSales : "Unknown"}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(response.content.status)}>Total Rows: {response.content.totalRows ? response.content.totalRows : ""}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(response.content.status)}>Anti-churn Rows: {response.content.antiChurnRows ? response.content.antiChurnRows : ""}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(response.content.status)}>Artist Revenue: {response.content.totalAmountCreditedToUsers ? response.content.totalAmountCreditedToUsers : "0"}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(response.content.status)}>Commission: {response.content.totalCommission ? response.content.totalCommission : "0"}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(response.content.status)}>GrossAmount: {response.content.grossAmount ? response.content.grossAmount : "0"}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(response.content.status)}>Timestamp: {response.content.timestamp ? response.content.timestamp : ""}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(response.content.status)}>Status: {response.content.status ? response.content.status : ""}</ListGroup.Item>
                                            </ListGroup>
                                        </div>}

          </div>
      }
        </div> 
      )
}