import { useState } from "react";
import Cookies from 'js-cookie'
import MossReportResponse from "./mossReportResponse";

export default function MossReport(props){

    const [isFormVisible, setFormVisible] = useState(true)
    const [isResponseVisible, setResponseVisible] = useState(false)
    const [mossData, setMossData] = useState({})
    const [submitData, setSubmitData] = useState({})

    const handleSubmit = (e)=>  {
        e.preventDefault()
        let cookieValue = Cookies.get('ReportHubAuth')
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/tools/mossreport?Quarter=${submitData.quarter}&Year=${submitData.year}`, {headers: {'Authorization': `Bearer ${cookieValue}`}})
        .then(res => {
            if (res.ok) {
                return res.json()
            } else {
                if (res.status === 400){
                    throw new Error(`Status code: ${res.status}. Please double check the input values`)
                }if (res.status === 401){
                    throw new Error(`Status code: ${res.status}. Unauthorized`)
                } else {
                    throw new Error(`Status code: ${res.status}.`)
                }
            }
        })
        .then(data =>{
            console.log("data")
            console.log(data)
            setMossData(data)
        }).catch(err => {
            let data = {}
            data.errorMessage = err.toString()
            data.isError = true
            console.log(data)
            setMossData(data)
        })
        setResponseVisibility(true)
    }

    function setResponseVisibility(isVisible){
        setFormVisible(!isVisible)
        setResponseVisible(isVisible)
    }

    const handleChange = (e) =>{
        console.log(submitData)
        if(e.target.name === "year"){
            submitData.year = e.target.value;
            console.log("year is changed")
        }
        if(e.target.name === "quarter"){
            submitData.quarter = e.target.value;
            console.log("quarter is changed")
        }
        setSubmitData(submitData)
    }

    return(
        <div>
            {isFormVisible &&
            <form className="row g-3 needs-validation" noValidate>
                <div className="col-md-3">
                    <label className="form-label">Year</label>
                    <select name="year" className="form-select" value={submitData.year} defaultValue="" onChange={handleChange}>
                    <option disabled value="">Choose...</option>
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>

                    </select>
                </div>
                <div className="col-md-3">
                    <label htmlFor="validationCustom04" className="form-label">Quarter</label>
                    <select name="quarter" className="form-select" value={submitData.quarter} defaultValue="" onChange={handleChange}>
                    <option disabled value="">Choose...</option>
                    <option value="1">Q1</option>
                    <option value="2">Q2</option>
                    <option value="3">Q3</option>
                    <option value="4">Q4</option>
                    </select>
                </div>
                <div className="col-12">
                    <button className="btn btn-primary" onClick={handleSubmit} type="submit">Generate Moss report</button>
                </div>
            </form>}
            <div>
                {isResponseVisible && <MossReportResponse quarter={submitData.quarter} year={submitData.year} data={mossData}/>}
            </div> 
        </div> 
    )
}