import './artificialStreamFeesResponse.css';
import {Container, Row, Col} from 'react-bootstrap'

export default function ArtificialStreamFeesResponse(props) {

    const numberFormat = (value) =>
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(value);

    if ( props.data.isError ){
        console.log("isError")
        console.log(props)
        return(
            <div className="response error">
                <b>{props.data.errorMessage}</b>
            </div>
            )
    }
    
    if ( props.data.date ) {
        console.log("data:")
        console.log(props.data)
    
        return(
            <div>
                <Container>
                    <Row>
                        <Col span="2" style={{display:'flex', justifyContent:'center'}}><h1>Artificial stream fees applied for {props.data.date}:</h1></Col>
                    </Row>
                    <Row>
                        <Col style={{display:'flex', justifyContent:'right'}}><h1>Total no of fees applied:</h1></Col>
                        <Col><h1>{props.data.feeCount}</h1></Col>
                    </Row>
                    <Row>
                        <Col style={{display:'flex', justifyContent:'right'}}><h1>Total sum of fees applied:</h1></Col>
                        <Col><h1>{numberFormat(props.data.feeSum)}</h1></Col>
                    </Row>
                </Container>
            </div>
        )
    }
    else{
        console.log(props)
        return(<div className="spinner-border text-primary" role="status">
        <span className="sr-only"></span>
      </div>)
    }

}