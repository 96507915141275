import './mossReportResponse.css';
import {Container, Row, Col} from 'react-bootstrap'
import {CSVLink} from 'react-csv';

export default function MossReportResponse(props) {

    const numberFormat = (value) =>
        new Intl.NumberFormat('sv-SE', {
            useGrouping: false
        }).format(value);

    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-EU', {
            style: 'currency',
            currency: 'EUR',
            separator: ','
        }).format(value);

    const rowRenderer = (r) => {
        console.log(r)
        let amount = parseFloat(r.amount)
        let tax = parseFloat(r.tax)

        return(
            <Row className="datarow" key={r.countryCode} >
                <Col><strong>{r.countryCode}</strong></Col>
                <Col>{currencyFormat(amount)}</Col>
                <Col>{currencyFormat(tax)}</Col>
            </Row>
        )
    }

    const csvReport = {
        data: [[ "MOSS_001" ], ["SE556752959801", props.quarter, props.year]],
        separator: ';',
        enclosingCharacter: '',
        filename: 'Moss_' + props.year + '_' + props.quarter + '_' + Date.now() + '.csv'
    }

    function setFileData(rows) {
        rows.map(r => 
            csvReport.data.push(["SE", r.countryCode, r.taxPercent, numberFormat(r.amount), numberFormat(r.tax)])
            )
    }

    if ( props.data.isError ){
        console.log("Error. props:")
        console.log(props)
        return(
            <div className="response error">
                <b>{props.data.errorMessage}</b>
            </div>
            )
    }
    
    if ( props.data.rows ) {
        setFileData(props.data.rows)
    
        return(
            <Container>
                <Row style={{width: '110%'}}>
                {<h1>Moss report for {props.data.period}</h1>}
                {<div>Exchange-rate USD-&gt;EUR: {props.data.exchangeRate}</div>}
                    <Row className="headerrow">
                        <Col><strong>Country-code</strong></Col>
                        <Col><strong>Accumulated amount</strong></Col>
                        <Col><strong>Accumulated tax</strong></Col>
                    </Row>
                    {props.data.rows.map(r => rowRenderer(r))}
                </Row>
                <br/>
                <div className="col-12">
                    <CSVLink {...csvReport} className="btn btn-primary">Download file</CSVLink>
               </div>
            </Container>
        )
    }
    else{
        return(<div className="spinner-border text-primary" role="status"><span className="sr-only"></span></div>)
    }
}