import { useState } from "react"
import Cookies from 'js-cookie'
import {Container, Row, Col, ToggleButton, ButtonGroup} from 'react-bootstrap'
import { FcOk, FcCancel } from "react-icons/fc";
import './report-automation.css'
import './bulkReportForm.css'



export default function BulkReportForm(){
    const [submitData, setSubmitData] = useState({})
    const [availableReportsResponse, setAvailableReportsResponse] = useState([])    
    const [availableReportsResponseVisibility, setAvailableReportsResponseVisibility] = useState(false)
    const [spinnerVisibility, setSpinnerVisibility] = useState(false)

    const [bulkProcessResponse, setBulkProcessResponse] = useState("")    
    const [bulkProcessResponseVisibility, setBulkProcessResponseVisibility] = useState(false)
    const [bulkReportButtinVisibility, setBulkReportButtonVisibility] = useState(true)
    const [processSpinnerVisibility, setProcessSpinnerVisibility] = useState(false)
    const [radioValue, setRadioValue] = useState('1');

    const radios = [
        { name: 'Ingestion Mode', value: '1' },
        { name: 'Trial Mode', value: '2' },
      ];


    const rowRenderer = (r) => {
        console.log(r)
        return(
            <Row className="datarow" key="1" >
                <Col>{r.filename}</Col>
                <Col><a href={r.fileLink}>Download report file</a></Col>
            </Row>
        )
    }


    const handleSubmitAvailableReports = (e)=>  {
        e.preventDefault()
        let cookieValue = Cookies.get('ReportHubAuth')
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/report-automation/available-reports?Month=${submitData.month}&Year=${submitData.year}`, {headers: {
            'Authorization': `Bearer ${cookieValue}`
          }})
        .then(res => {
            if (res.ok) {
                return res.json()
            } else {
                if (res.status === 400){
                    throw new Error(`Status code: ${res.status}. Please double check the input values`)
                }if (res.status === 404){
                    throw new Error(`Status code: ${res.status}. Report file not found on GCS`)
                }if (res.status === 401){
                    throw new Error(`Status code: ${res.status}. Unauthorized`)
                } else {
                    throw new Error(`Status code: ${res.status}.`)
                }
            }
        })
        .then(data =>{
            console.log("Response is :")
            console.log(data)
            const resp = {isError: false, data: data}
            setSpinnerVisibility(false)
            setAvailableReportsResponse(resp)
            setAvailableReportsResponseVisibility(true)
        }).catch(err => {
            let data = {}
            data.errorMessage = err.toString()
            data.isError = true
            setSpinnerVisibility(false)
            setAvailableReportsResponse(data)
            setAvailableReportsResponseVisibility(true)
        })
        setSpinnerVisibility(true)

    }

    const handleSubmitProcessReports = (e)=>  {
        e.preventDefault()
        let cookieValue = Cookies.get('ReportHubAuth')
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/report-automation/all?Month=${submitData.month}&Year=${submitData.year}&isTrialRun=${radioValue !== '1'}`,
        {
            headers: {'Authorization': `Bearer ${cookieValue}`},
            method: 'POST'}
            )
        .then(res => {
            if (res.ok) {
                return res.text()
            } else {
                if (res.status === 400){
                    throw new Error(`Status code: ${res.status}. Please double check the input values`)
                }if (res.status === 404){
                    throw new Error(`Status code: ${res.status}. Report file not found on GCS`)
                }if (res.status === 401){
                    throw new Error(`Status code: ${res.status}. Unauthorized`)
                } else {
                    throw new Error(`Status code: ${res.status}.`)
                }
            }
        })
        .then(data =>{
            console.log("Response is :")
            console.log(data)
            const resp = {isError: false, data: data}
            setBulkProcessResponse(resp)
            setProcessSpinnerVisibility(false)
            setBulkProcessResponseVisibility(true)
        }).catch(err => {
            let data = {}
            data.errorMessage = err.toString()
            data.isError = true
            setBulkProcessResponse(data)
            setProcessSpinnerVisibility(false)
            setBulkProcessResponseVisibility(true)
        })
        setProcessSpinnerVisibility(true)

    }

    const handleChange = (e) =>{
        console.log(submitData)
        if(e.target.name === "year"){
            submitData.year = e.target.value;
            console.log("year is changed")
        }
        if(e.target.name === "month"){
            submitData.month = e.target.value;
            console.log("month is changed")
        }
        setSubmitData(submitData)
    }

    return(
        <div>
            <form className="row g-3 needs-validation" noValidate>
                <div className="col-md-3">
                    <label className="form-label">Year</label>
                    <select name="year" className="form-select" value={submitData.year} onChange={handleChange}>
                    <option selected disabled value="">Choose...</option>
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>

                    </select>
                </div>
                <div className="col-md-3">
                    <label for="validationCustom04" className="form-label">Months</label>
                    <select name="month" className="form-select" value={submitData.month} onChange={handleChange}>
                    <option selected disabled value="">Choose...</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                    </select>
                </div>
                <div className="col-12">
                    <button className="btn btn-primary" onClick={handleSubmitAvailableReports} type="submit">Fetch available reports</button>
                </div>
                <p></p>
                </form>

                { spinnerVisibility && <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only"></span>
                                        </div>}

                { availableReportsResponseVisibility && <div>
                                    {availableReportsResponse.isError && <h6>{availableReportsResponse.errorMessage} <FcCancel></FcCancel> </h6>}
                                    {!availableReportsResponse.isError && 
                                    <div className={radioValue !== '1' ? "alert" : ""}>
                    <Container>
                <Row style={{width: '100%'}}>
                {<h1>Available reports for {submitData.year} - {submitData.month} </h1>}
                    <Row className="headerrow">
                        <Col><strong>Filename</strong></Col>
                        <Col><strong>Link to file</strong></Col>
                    </Row>
                    <div style={{margin: '10px'}}>{availableReportsResponse.data.map(r => rowRenderer(r))}</div>
                    
                </Row>
                <Row>
                <div className="col-12">
                <br />
                <ButtonGroup>
                    {radios.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant={idx % 2 ? 'outline-danger' : 'outline-success'}
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                    >
                        {radio.name}
                    </ToggleButton>
                    ))}
                </ButtonGroup>
                <p></p>
                <br />
                    <button className="btn btn-primary" onClick={handleSubmitProcessReports}>{radioValue !== '1' ? "Ingest Reports on Trial Mode" : "Ingest reports"}</button>
                </div>
                </Row>
            </Container>
                </div>

                }</div>}
            { processSpinnerVisibility && <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only"></span>
                                            <br />
                                        </div>}
            {bulkProcessResponseVisibility && <div><br />
                {bulkProcessResponse.isError && <h4> <FcCancel></FcCancel> <strong>Server responded with error: </strong> {bulkProcessResponse.errorMessage}  </h4>}
                {!bulkProcessResponse.isError && <h6> <FcOk></FcOk> <strong>Ingestion Identifier: </strong>{bulkProcessResponse.data}  </h6>}

          </div>
      }


        </div> 
      )
}