import { Container, Row, ListGroup } from "react-bootstrap"
import './allReportsResponse.css'
import Legend from '../legend'

export default function AllReportsResponse(props){

    const getStatus = (isComplete, status) => {
            if (!isComplete){
                return "danger"
            }
            if (status === "success"){
                return "success"
            }
            if (status === "warning"){
                return "warning"
            }
            if(status === "error"){
                return "danger"
            }
        }
    
    
        if ( props.data.isError ){
            console.log("arrived")
            console.log(props)
            return(
                <div className="response error">
                    <b>{props.data.errorMessage}</b>
                </div>
                )
        } if ( props.data.length > 0 ) {
            let counter = 0
            return(
                <Container className="results">
                    <br />
                    <Row className="results"><h2>Results</h2></Row>
                    <Legend />
                    <Row>
                        {props.data.map( d => 
                                        <div>
                                            <ListGroup horizontal="sm" className="my-2" key={counter++}>
                                                <ListGroup.Item variant={getStatus(d.isComplete, d.status)}><strong>DSP name:</strong> {d ? d.dspName : ""}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.isComplete, d.status)}>Lines processed: {d ? d.totalLinesProcessed : ""}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.isComplete, d.status)}>Error lines: {d ? d.errorLines : ""}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.isComplete, d.status)}>Output file: {d ? d.outputFile : ""}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.isComplete, d.status)}>Album Sales Revenue: {d ? d.revenue.toFixed(2)+"$" : ""}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.isComplete, d.status)}>Original Report Revenue: {d ? d.grossRevenue.toFixed(2)+"$" : ""}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.isComplete, d.status)}>Last month Revenue: {d ? d.lastMonthRevenue.toFixed(2)+"$" : ""}</ListGroup.Item>
                                            </ListGroup>
                                        </div>
                        )}
                    </Row>
                </Container>
            )
        }
        else{
            console.log(props)
            return(<div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
            </div>)
        }
    
}