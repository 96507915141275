import './etl.css'
import {useState} from 'react'
import IndividualReportForm from './individualReport/individualReportForm'
import IndividualResponse from './individualReport/individualResponse'
import ProcessingMonthlyStatus from './monthlyProcessing/reportProcessingMonthlyStatus'
import MontlyProcessingResponse from './monthlyProcessing/monthlyProcessingResponse'
import AllReportsForm from './allReports/allReportsForm'
import AllReportsConfirmationTab from './allReports/allReportsConfirmationTab'
import AllReportsResponse from './allReports/allReportsResponse'
import FeedBackTab from './allReports/feedBackTab'
import ReportDefects from './reportDefects/reportDefects'
import {Accordion} from 'react-bootstrap'


export default function Etl(){
    const [isIndividualFormVisible, setIndividualFormVisibility] = useState(false)
    const [isMonthlyProcessingVisible, setMonthlyProcessingVisibility] = useState(false)

    const [isIndividualResponseVisible, setIndividualResponseVisible] = useState(false)
    const [isMonthlyProcessingResponseVisible, setMonthlyProcessingResponseVisible] = useState(false)

    const [dateOfRun, setDateOfRun] = useState({})

    const [individualFormResponse, setIndividualFormResopnse] = useState({})
    const [monthlyProcessingResponse, setMonthlyProcessingResopnse] = useState([])

    const [feedback, setFeedback] = useState([])
    const [feedbackId, setFeedbackId] = useState("")
    const [isFeedBackVisible, setFeedBackVisibility] = useState(false)
    const [allReportsDateOfRun, setAllReportsDateOfRun] = useState({})
    const [allReportsConfirmation, setAllReportsConfirmation] = useState([])
    const [allReportsResponse, setAllReportsResponse] = useState([])
    const [isAllReportsFormVisible, setAllReportsFormVisibility] = useState(false)
    const [isAllReportsConfirmationTabVisible, setAllReportsConfirmationTabVisibility] = useState(false)
    const [isAllReportsResponseVisible, setAllReportsResponseVisibility] = useState(false)
    const [isReportDefectsVisible, setReportDefectVisibility] = useState(false)


    const toggleIndividualForm = () => {
        setIndividualFormVisibility(!isIndividualFormVisible)
        setIndividualResponseVisible(false)
        setIndividualFormResopnse({})
    }

    const toggleMonthlyProcessing = () => {
        setMonthlyProcessingVisibility(!isMonthlyProcessingVisible)
        setMonthlyProcessingResponseVisible(false)
        setMonthlyProcessingResopnse([])
    }

    function toggleAllReportsForm(){
        setAllReportsFormVisibility(!isAllReportsFormVisible)
        setAllReportsConfirmationTabVisibility(false)
        setAllReportsResponseVisibility(false)
        setAllReportsConfirmation([])
    }

    function toggleReportDefectVisibility(){
        setReportDefectVisibility(!isReportDefectsVisible)
    }

    return(
        <div>
        <h1>ETL service</h1>
        <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
                <Accordion.Header><h5>Information and Instructions</h5></Accordion.Header>
                <Accordion.Body>
                <div>
                ETLs will transform all formats from our DSPs to our Record Union format, ready for processing though the royalty reporting flow. The reports are gathered 
                from Google Drive, AWS S3 bucket and email. Unfortunately some DSPs still require some manual work in order to gather all the necessary files. Some 
                examples of these DSPs are Apple music & itunes (we need to manually visit the apple music portal and download 3 files), fuganapster, etc... 
                For the DSPs above, the collected reports need to be placed in the <a href="https://console.cloud.google.com/storage/browser/reports-for-processing-prod/2021/09;tab=objects?pageState=(%22StorageObjectListTable%22:(%22f%22:%22%255B%255D%22))&project=prj-reng-p-svc&prefix=&forceOnObjectsSortingFiltering=false">google cloud storage</a>  in the folder for the right year, month and dsp. 
                <p></p>
                The ETL page contains three functionalities.
                <br></br>
                <ul>
                <li><string>Process Individual Report</string>: Select one dsp for a year and month, and this report will be transformed to RU format</li>
                <li><string>Report Processing Monthly Status</string>: The user gets a summary of unprocessed (available) and processed (reports ready to process) for specific year and month</li>
                <li><string>Process All Reports</string>: After selecting a year and month, the user gets a list of all reports to be tranformed & after confirming, tranformation starts</li>
                </ul>
                </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>

        <div>
            <div className="row mt-lg-4">
                <button type="button" onClick={toggleIndividualForm} className="btn btn-primary btn-lg">Process Individual Report</button>
            </div>
            <div className="mt-lg-4">
                {isIndividualFormVisible && <IndividualReportForm setVisibility={setIndividualFormVisibility} setResponse={setIndividualFormResopnse} setResponseVisible={setIndividualResponseVisible}/>}
                {isIndividualResponseVisible && <IndividualResponse {...individualFormResponse}/>}

            </div>
        </div>

        <div>
            <div className="row mt-lg-4">
                <button type="button" onClick={toggleMonthlyProcessing} className="btn btn-primary btn-lg">Report processing monthly status</button>
            </div>
            <div className="mt-lg-4">
                {isMonthlyProcessingVisible && <ProcessingMonthlyStatus setVisibility={setMonthlyProcessingVisibility} setResponse={setMonthlyProcessingResopnse} setResponseVisible={setMonthlyProcessingResponseVisible} setDateOfRun={setDateOfRun}/>}
                {isMonthlyProcessingResponseVisible && <MontlyProcessingResponse {...dateOfRun} data={monthlyProcessingResponse}/>}

            </div>
        </div>

        <div className="row mt-lg-4">
            <button type="button" onClick={toggleAllReportsForm} className="btn btn-primary btn-lg">Process all reports</button>
        </div>
        <div className="mt-lg-4">
                {isAllReportsFormVisible && <AllReportsForm setVisibility={setAllReportsFormVisibility} setResponse={setAllReportsConfirmation} setConfirmationTabVisibility={setAllReportsConfirmationTabVisibility} setDateOfRun={setAllReportsDateOfRun}/>}
                {isAllReportsConfirmationTabVisible && <AllReportsConfirmationTab {...allReportsDateOfRun} data={allReportsConfirmation} setFeedbackId={setFeedbackId} setResponseVisibility={setAllReportsResponseVisibility} setFeedBackVisibility={setFeedBackVisibility} setResponse={setAllReportsResponse}/>}
                {isFeedBackVisible && <FeedBackTab feedbackId={feedbackId} feedback={feedback} setFeedback={setFeedback}/>}
                {isAllReportsResponseVisible && <AllReportsResponse {...allReportsDateOfRun} data={allReportsResponse}/>}
            </div>

        <div className="row mt-lg-4">
            <button type="button" onClick={toggleReportDefectVisibility} className="btn btn-primary btn-lg">Report Defects</button>
        </div>
        <div className="mt-lg-4">
                {isReportDefectsVisible && <ReportDefects />}
               
            </div>
        </div>

        
    )
}