import './validateReportsResponse.css';
import {Container, Row, Col} from 'react-bootstrap'

export default function ValidateReportsResponse(props) {

    const numberFormat = (value) =>
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(value);

    const rowRenderer = (r) => {
        console.log(r)
        let revenue = parseFloat(r.revenue)
        let previousRevenue = parseFloat(r.previousRevenue)
        let count = parseInt(r.count)
        let previousCount = parseInt(r.previousCount)

        let revenueDiff = GetDiffPercent(revenue, previousRevenue)
        let countDiff = GetDiffPercent(count, previousCount)

        let revenueClass = GetCellClass(revenueDiff)
        let countClass = GetCellClass(countDiff)

        return(
            <Row className="datarow" key={r.dsp}>
                <Col><strong>{r.dsp}</strong></Col>
                <Col>{numberFormat(revenue)}</Col>
                <Col>{numberFormat(previousRevenue)}</Col>
                <Col className={revenueClass}>{FormatDiff(revenueDiff)}</Col>
                <Col>{count}</Col>
                <Col>{previousCount}</Col>
                <Col className={countClass}>{FormatDiff(countDiff)}</Col>
            </Row>
        )
    }

    function FormatDiff(value){
        let plus = (value > 0 ? '+' : '')
        return plus + value.toFixed(2) + '%'
    }

    function GetDiffPercent(value, oldValue){
        if(oldValue === 0){
            if(value === 0)
                return 0
            return 100
        }
        let revenueDiff = value - oldValue
        let diffPercent = revenueDiff / oldValue * 100
        return diffPercent
    }

    function GetCellClass(diff){
        if(diff >= 0)
            return ""
        if(diff <= -20)
            return "cellerror"
        if(diff <= -10)
            return "cellwarn2"
        return "cellwarn1"
    }

    if ( props.data.isError ){
        console.log("arrived")
        console.log(props)
        return(
            <div className="response error">
                <b>{props.data.errorMessage}</b>
            </div>
            )
    }
    
    if ( props.data.length > 0 ) {
        console.log("data")
        console.log(props.data)
    
        return(
            <Container>
                <Row style={{width: '110%'}}>
                {<h1>Royalty reporting validation data for {props.month} {props.year} </h1>}
                    <Row className="headerrow">
                        <Col><strong>DSP</strong></Col>
                        <Col><strong>Revenue</strong></Col>
                        <Col><strong>Previous Revenue</strong></Col>
                        <Col><strong>Revenue Diff %</strong></Col>
                        <Col><strong>Count</strong></Col>
                        <Col><strong>Previous Count</strong></Col>
                        <Col><strong>Count Diff %</strong></Col>
                    </Row>
                    {props.data.map(r => rowRenderer(r))}
                </Row>
            </Container>
        )
    }
    else{
        console.log(props)
        return(<div className="spinner-border text-primary" role="status">
        <span className="sr-only"></span>
      </div>)
    }

}