import { useState } from "react";
import Cookies from 'js-cookie'
import ArtificialStreamFeesResponse from "./artificialStreamFeesResponse";

export default function ArtificialStreamFees(props){

    const [isFormVisible, setFormVisible] = useState(true)
    const [isResponseVisible, setResponseVisible] = useState(false)
    const [validationData, setValidationData] = useState({})
    const [submitData, setSubmitData] = useState({})

    const handleSubmit = (e)=>  {
        e.preventDefault()
        let cookieValue = Cookies.get('ReportHubAuth')
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/tools/artificialstreamfees?Month=${submitData.month}&Year=${submitData.year}`, {headers: {'Authorization': `Bearer ${cookieValue}`}})
        .then(res => {
            if (res.ok) {
                return res.json()
            } else {
                if (res.status === 400){
                    throw new Error(`Status code: ${res.status}. Please double check the input values`)
                }if (res.status === 401){
                    throw new Error(`Status code: ${res.status}. Unauthorized`)
                } else {
                    throw new Error(`Status code: ${res.status}.`)
                }
            }
        })
        .then(data =>{
            console.log(data)
            setValidationData(data)
        }).catch(err => {
            let data = {}
            data.errorMessage = err.toString()
            data.isError = true
            console.log(data)
            setValidationData(data)
        })
        setResponseVisibility(true)
    }

    function setResponseVisibility(isVisible){
        setFormVisible(!isVisible)
        setResponseVisible(isVisible)
    }

    const handleChange = (e) =>{
        console.log(submitData)
        if(e.target.name === "year"){
            submitData.year = e.target.value;
            console.log("year is changed")
        }
        if(e.target.name === "month"){
            submitData.month = e.target.value;
            console.log("month is changed")
        }
        setSubmitData(submitData)
    }

    return(
        <div>
            {isFormVisible &&
            <form className="row g-3 needs-validation" noValidate>
                <div className="col-md-3">
                    <label className="form-label">Year</label>
                    <select name="year" className="form-select" value={submitData.year} defaultValue="" onChange={handleChange}>
                        <option disabled value="">Choose...</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                        <option value="2027">2027</option>
                        <option value="2028">2028</option>
                        <option value="2029">2029</option>
                        <option value="2030">2030</option>
                    </select>
                </div>
                <div className="col-md-3">
                    <label htmlFor="validationCustom04" className="form-label">Month</label>
                    <select name="month" className="form-select" value={submitData.month} defaultValue="" onChange={handleChange}>
                        <option disabled value="">Choose...</option>
                        <option value="January">January</option>
                        <option value="February">February</option>
                        <option value="March">March</option>
                        <option value="April">April</option>
                        <option value="May">May</option>
                        <option value="June">June</option>
                        <option value="July">July</option>
                        <option value="August">August</option>
                        <option value="September">September</option>
                        <option value="October">October</option>
                        <option value="November">November</option>
                        <option value="December">December</option>
                    </select>
                </div>
                <div className="col-12">
                    <button className="btn btn-primary" onClick={handleSubmit} type="submit">Apply fees</button>
                </div>
            </form>}
            <div>
                {isResponseVisible && <ArtificialStreamFeesResponse month={submitData.month} year={submitData.year} data={validationData}/>}
            </div> 
        </div> 
  )
}