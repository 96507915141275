import { Container, Row, Col, ListGroup, Button } from "react-bootstrap"
import { useState } from "react"
import Cookies from 'js-cookie'
import './allReportsConfirmation.css'

export default function AllReportsConfirmationTab(props){

    const [buttonIsVisible, setButtonVisibility] = useState(true)


    let fileExist = [] 
    let fileDoesNotExist = []
    if(!props.data.isError){
        fileExist = props.data.filter(r => r.inputFileExists)
        fileDoesNotExist = props.data.filter(r => !r.inputFileExists)
    }


    console.log("Exist")
    console.log(fileExist)
    console.log("fileDoesNotExist")
    console.log(fileDoesNotExist)

    const eightDigits = (s) => {  
        var p = (Math.random().toString(16)+"000000000").substr(2,8);  
        return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;  
     }  
    const CreateGuid = () => {   
        return eightDigits() + eightDigits(true) + eightDigits(true) + eightDigits();  
     }  
 
    const feedbackId = CreateGuid()

    const processAllReports = (e)=>  {
        e.preventDefault()

        props.setFeedbackId(feedbackId)
        let urlComponent = "";
        fileExist.forEach(file => urlComponent += `AvailableDsps=${file.dsp}&`)
        let cookieValue = Cookies.get('ReportHubAuth')
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/etls/all?${urlComponent}Month=${props.month}&Year=${props.year}`, { headers: 
            {'Authorization': `Bearer ${cookieValue}`,
            'feedback-id': `${feedbackId}`
        }})
        .then(res => {
            if (res.ok) {
                return res.json()
            } else {
                if (res.status === 400){
                    throw new Error(`Status code: ${res.status}. Please double check the input values`)
                }if (res.status === 404){
                    throw new Error(`Status code: ${res.status}. Report file not found on GCS`)
                }if (res.status === 401){
                    throw new Error(`Status code: ${res.status}. Unauthorized`)
                } else {
                    throw new Error(`Status code: ${res.status}.`)
                }
            }
        })
        .then(data =>{
            props.setResponse(data)
            props.setFeedBackVisibility(false)
            props.setResponseVisibility(true)
        }).catch(err => {
            let data = {}
            data.errorMessage = err.toString()
            data.isError = true
            console.log(data)
            props.setResponse(data)
            props.setFeedBackVisibility(false)
        })


        props.setFeedBackVisibility(true)
        setButtonVisibility(false)
    }

    if ( props.data.isError ){
        console.log("arrived")
        console.log(props)
        return(
            <div className="response error">
                <b>{props.data.errorMessage}</b>
            </div>
            )
    } if ( props.data.length > 0 ) {
        return(
            <Container>
                <Row>
                {<h1>{props.month} - {props.year} </h1>}
                    <Row>
                        <Col>
                            <h2>Dsps to be processed</h2>
                            <ListGroup>
                                {fileExist.map(r => <ListGroup.Item variant="success">{r.dsp}</ListGroup.Item>)}
                            </ListGroup>
                        </Col>
                        <Col>
                        <h2>Dsps Missing</h2>
                        <ListGroup>
                                {fileDoesNotExist.map(r => <ListGroup.Item variant="danger">{r.dsp}</ListGroup.Item>)}
                            </ListGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4} className={buttonIsVisible ? "" : "hidden"}>
                            <Button variant="primary" onClick={processAllReports}>Continue</Button>
                        </Col>
                    </Row>
                </Row>
            </Container>
        )
    }
    else{
        console.log(props)
        return(<div className="spinner-border text-primary" role="status">
        <span className="sr-only"></span>
        </div>)
    }
}