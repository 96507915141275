import { useState } from "react"
import Cookies from 'js-cookie'
import { FcCancel } from "react-icons/fc";
import {ListGroup} from 'react-bootstrap'



export default function Results(){
    const [identifier, setIdentifier] = useState("")

    const [response, setResponse] = useState({})
    const [reponseArrived, setResponseArrived] = useState(false)

    const getStatus = (status) => {
        if (status === "Completed"){
            return "success"
        }
        if (status === "Failed"){
            return "danger"
        }
        if (status === "Started"){
            return "info"
        }
    }

    const handleSubmit = (e)=>  {
        e.preventDefault()
        let cookieValue = Cookies.get('ReportHubAuth')
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/report-automation/process-results?ingestionGuid=${identifier}`, 
        {
            headers: {
            'Authorization': `Bearer ${cookieValue}`
          }})
        .then(res => {
            if (res.ok) {
                return res.json()
            } else {
                if (res.status === 400){
                    throw new Error(`Status code: ${res.status}. Please double check the input values`)
                }if (res.status === 404){
                    throw new Error(`Status code: ${res.status}.`)
                }if (res.status === 401){
                    throw new Error(`Status code: ${res.status}. Unauthorized`)
                } else {
                    throw new Error(`Status code: ${res.status}.`)
                }
            }
        })
        .then(data =>{
            const resp = {isError: false, content: data}
            if(resp.content.adminResponses.length === 0) {
                throw new Error("The response is empty.")
            }
            setResponse(resp)
            setResponseArrived(true) 
        }).catch(err => {
            let resp = {}
            resp.errorMessage = err.toString() + " Please check the identifier provided."
            resp.isError = true
            setResponse(resp)
            setResponseArrived(true) 
        })
    }

    let counter = 0
    return(
        <div>
            <p></p>
            <form className="row g-3 needs-validation" noValidate>
                <div className="col-md-3">
                    <label className="form-label">Enter ingestion identifier: </label>
                    <input value={identifier} onInput={e => setIdentifier(e.target.value)}/>
                </div>
                <div className="col-12">
                    <button className="btn btn-primary" onClick={handleSubmit} type="submit">Get results</button>
                </div>
            </form>
            <p></p>
      {
          reponseArrived && <div>
                           
                {response.isError && <h6>{response.errorMessage} <FcCancel></FcCancel> </h6>}
                {!response.isError && <div> {response.content.adminResponses[0].isTrialRun ? <h3>OBS: These results obtained in Trial Run</h3> : ""}
                                            <p></p>
                                            <h3>Total amount credited to users: {response.content.totalAmountCreditedToUsers ? response.content.totalAmountCreditedToUsers : "0"}$</h3>
                                            <h3>Total Commission: {response.content.totalCommission ? response.content.totalCommission : "0"}$</h3>
                                            <h3>Total Gross Revenue: {response.content.grossAmount ? response.content.grossAmount : "0"}$</h3>
                                            <p></p>
                                            {response.content.adminResponses.map( d => 
                                        <div>
                                            <ListGroup horizontal="sm" className="my-2" key={counter++}>
                                                <ListGroup.Item variant={getStatus(d.status)}>Ingestion Guid: {d.ingestionGuid ? d.ingestionGuid : "Unknown"}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.status)}>Process Guid: {d.processGuid ? d.processGuid : "Unknown"}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.status)}>Filelink: <a href={d.fileLink ? d.fileLink : ""}>Link</a></ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.status)}>Filename: {d.filename ? d.filename : ""}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.status)}>Error Message: {d.errorMessage ? d.errorMessage : "None"}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.status)}>Number Of Sales: {d.numberOfSales ? d.numberOfSales : "Unknown"}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.status)}>Total Rows: {d.totalRows ? d.totalRows : ""}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.status)}>Anti-churn Rows: {d.antiChurnRows ? d.antiChurnRows : ""}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.status)}>Commission: {d.totalCommission ? d.totalCommission : "0"}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.status)}>GrossAmount: {d.grossAmount ? d.grossAmount : "0"}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.status)}>Artists revenue: {d.totalAmountCreditedToUsers ? d.totalAmountCreditedToUsers : "0"}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.status)}>Timestamp: {d.timestamp ? d.timestamp : ""}</ListGroup.Item>
                                                <ListGroup.Item variant={getStatus(d.status)}>Status: {d.status ? d.status : ""}</ListGroup.Item>
                                            </ListGroup>
                                        </div>)}</div>}

          </div>
      }
        </div> 
      )
}