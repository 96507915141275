import './report-automation.css'
import {useState, useEffect} from 'react'
import Cookies from 'js-cookie'
import {Container, Accordion, Row, ListGroup, Col, Card} from 'react-bootstrap'
import Results from './results'
import Result from './result'
import BulkReportForm from './bulkReportForm'
import SingleReportForm from './singleReportForm'

export default function ReportAutomation(){
    const [isMonthlyProcessingVisible, setMonthlyProcessingVisibility] = useState(false)
    const [isMonthlyProcessingResponseVisible, setMonthlyProcessingResponseVisible] = useState(false)
    const [monthlyProcessingResponse, setMonthlyProcessingResopnse] = useState([])
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const [statuses, setStatuses] = useState([])
    const [newStatuses, setNewStatuses] = useState([])
    const [statusesVisibility, setStatusesVisibility] = useState(true)
    const [resultsVisibility, setResultsVisibility] = useState(false)
    const [resultVisibility, setResultVisibility] = useState(false)
    const [statusesError, setStatusesError] = useState(false)
    const [statusesPage, setStatusesPage] = useState(0)
    const [singleReportFormVisibility, setSingleReportFormVisibility] = useState(false)

    let page = 0

    const nextPage = () => {
        console.log("NextPageTriggered")
        page = statusesPage +1
        setStatusesPage(page, loadStatuses())
        
        console.log(statusesPage)
    }

    const previousPage = () => {
        console.log("PreviousPageTriggered")
        page = statusesPage === 0 ? statusesPage : statusesPage -1
        setStatusesPage(page, loadStatuses())
        console.log(statusesPage)
    }

    const getStatus = (status) => {
        if (status === "Completed"){
            return "success"
        }
        if (status === "Failed"){
            return "danger"
        }
        if (status === "Started"){
            return "info"
        }
    }

    const loadStatuses = () => {
        let cookieValue = Cookies.get('ReportHubAuth')
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/report-automation/statuses?page=${page}`, {headers: {
            'Authorization': `Bearer ${cookieValue}`
          }})
        .then(res => {
            if (res.ok) {
                return res.json()
            } else {
                if (res.status === 400){
                    throw new Error(`Status code: ${res.status}. Please double check the input values`)
                }if (res.status === 401){
                    throw new Error(`Status code: ${res.status}. Unauthorized`)
                } else {
                    throw new Error(`Status code: ${res.status}.`)
                }
            }
        })
        .then(data =>{
            console.log(data)
            setStatuses(data)
            console.log("New statuses:")
            setNewStatuses(data)
        }).catch(err => {
            console.log("error is: " + {err})
            setStatusesError(true)
        })
    }

    useEffect(() => {
        loadStatuses()
    }, [])

    const singleReportFormVisibilitiesSetter = () => {
        setStatusesVisibility(false)
        setSingleReportFormVisibility(!singleReportFormVisibility)
    }

    const resultsVisibilitySetter = () => {
        setResultsVisibility(!resultsVisibility)
        setStatusesVisibility(false)
    }

    const resultVisibilitySetter = () => {
        setResultVisibility(!resultVisibility)
        setStatusesVisibility(false)
    }

    const redirectToImportHistory = () => {
        console.log(process.env)
        window.open(`${process.env.REACT_APP_ADMIN_BASE_URL}/DSPReport/History`, '_blank')
    }

    const toggleMonthlyProcessing = () => {
        setMonthlyProcessingVisibility(!isMonthlyProcessingVisible)
        setMonthlyProcessingResponseVisible(false)
        setMonthlyProcessingResopnse([])
    }


    const rowRenderer = (r) => {
        console.log(r)
        return(
            <Row className="datarow" key="1" >
                <Col>{r.processType}</Col>
                <Col>{r.processStatus}</Col>
                <Col>{r.filename}</Col>
                <Col>{r.ingestionGuid}</Col>
                <Col>{r.processGuid}</Col>
                <Col>{r.reportDate.slice(0, 10)}</Col>
                <Col>{r.timestamp}</Col>
                <Col>{(r.processType === "All" && r.processStatus === "Started") && <button id="previousPage" className="btn btn-primary" onClick={previousPage}>Expand</button>}</Col>
            </Row>
        )
    }
    let counter = 0
    return(
        <div>
        <h1>Report automation portal</h1>
        <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
                <Accordion.Header><h5>Information and Instructions</h5></Accordion.Header>
                <Accordion.Body>
                <div>
                The Report Automation Portal will let you ingest reports into Admin in an automated manner. Ingest refers to the action of importing reports into Admin (single or mulitple) and 
                process refers to the action of actual processing an individual report (e.g. you can find ingestion-statuses and process-results (the results from Admin)).  
                When the page loads, the latest ingestion processes along with their statuses are loaded in the page.
                <p></p>
                The ETL page contains three functionalities.
                <br></br>
                <ul>
                <li><string>Submit Single Report</string>: Insert the filename and submit. Admin will figure out the report date and the rest of the necessary info. OBS the file name has to comply with the new naming standards. The response is the ingestion identifier, which can then be tracked in the homepage ingestion-statuses, in order to confirm the final status</li>
                <li><string>Submit Monthly Reports</string>: After selecting year and month, the user gets a list of report files and confirms the sendout. An ingestion identifier is returned almost immediately, which can then be tracked in the homepage ingestion-statuses in order to confirm the final status</li>
                <li><string>Monthly process results</string>: By using the ingestion identifier the user can track the results (Admin responses) of the whole ingestion</li>
                <li><string>Single Process Results</string>: By using the process identifier the user gets the result (Admin response) for one specific process</li>
                </ul>

                By clicking on the link shown in the results pages, you can download the error-responses file to your pc.
                </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <div>
            <div className="row mt-lg-4">
                <button type="button" onClick={singleReportFormVisibilitiesSetter} className="btn btn-primary btn-lg">Submit Single Report</button>
            </div>
            <div className="mt-lg-4">
                {singleReportFormVisibility && <SingleReportForm />}
            </div>
        </div>

        <div>
            <div className="row mt-lg-4">
                <button type="button" onClick={toggleMonthlyProcessing} className="btn btn-primary btn-lg">Submit Monthly Reports</button>
            </div>
            <div className="mt-lg-4">
                {isMonthlyProcessingVisible && <BulkReportForm/>}
            </div>
        </div>

        <div className="row mt-lg-4">
            <button type="button" onClick={resultsVisibilitySetter} className="btn btn-primary btn-lg">Monthly process results</button>
        </div>
        <div className="mt-lg-4">
                {resultsVisibility && <Results />}
            </div>
        
         <div className="row mt-lg-4">
            <button type="button" onClick={resultVisibilitySetter} className="btn btn-primary btn-lg">Single process results</button>
        </div>
        <div className="mt-lg-4">
                {resultVisibility && <Result />}
            </div>
        <div className="row mt-lg-4">
            <button type="button" onClick={redirectToImportHistory} className="btn btn-secondary btn-lg">Go to Imports History in Admin</button>
        </div>
        <p></p>


        {statusesVisibility && 
            <Container>
                <Row>
                {<h1>Latest Report ingestion statuses </h1>}
                    <Row>
                        {statusesError && <div><br /><h3>An error occured fetching ingestion statuses</h3></div>}
                        {newStatuses.map(s => <Row><Card className={s.isTrialRun ? "attention" : "normal"} style={{ width: '70rem' }}>
                                                <Card.Body>
                                                    <Card.Title>{s.ingestionType} Ingestion {s.ingestionCompletionStatus} {s.isTrialRun ? <strong>&emsp;&emsp;&emsp;OBS: Ingestion in Trial mode</strong> : "" }</Card.Title>
                                                    <Card.Subtitle className="mb-2 text-muted"><strong>Ingestion guid:</strong> {s.ingestionGuid}</Card.Subtitle>
                                                    <Card.Subtitle className="mb-2 text-muted"><strong>Ingestion started:</strong> {s.ingest}started: {s.ingestionsStarted}</Card.Subtitle>
                                                    <Card.Subtitle className="mb-2 text-muted"><strong>Ingestion ended:</strong> {s.ingest}Ingestion ended: {s.ingestionResulted}</Card.Subtitle>
                                                    <Card.Subtitle className="mb-2 text-muted"><strong>Report Date:</strong> {s.reportDate.slice(0, 10)}</Card.Subtitle>
                                                    <Card.Subtitle className="mb-2 text-muted"><strong>Ingestion Type:</strong> {s.ingestionType}</Card.Subtitle>
                                                    <Card.Subtitle className="mb-2 text-muted"><strong>Ingestion ended:</strong> {s.ingestionResulted}</Card.Subtitle>


                                                    <Card.Text>
                                                        {s.singleIngestions.map(d => 
                                                            <ListGroup horizontal="sm" className="my-2" key={counter++}>
                                                                <ListGroup.Item variant={getStatus(d.status)}><strong>Process Identifier:</strong> {d ? d.processGuid : ""}</ListGroup.Item>
                                                                <ListGroup.Item variant={getStatus(d.status)}>Filename: {d ? d.filename : ""}</ListGroup.Item>
                                                                <ListGroup.Item variant={getStatus(d.status)}>Status: {d ? d.status : ""}</ListGroup.Item>
                                                                <ListGroup.Item variant={getStatus(d.status)}>Timestamp: {d ? d.timestamp : ""}</ListGroup.Item>
                                                            </ListGroup>
                                                        )}
                                                   
                                                    </Card.Text>
                                                    {/* <Card.Link href="#">Card Link</Card.Link>
                                                    <Card.Link href="#">Another Link</Card.Link> */}
                                                </Card.Body>
                                                </Card></Row>)}
                    </Row>
                </Row>
                {!statusesError && <Row>
                <Col>  
                    <button id="previousPage" className="btn btn-primary" onClick={previousPage}>Previous</button>
                </Col>
                <Col xs={1}>                {<h4>{statusesPage}</h4>}</Col>
                <Col>      
                    <button id="nextPage" className="btn btn-primary" onClick={nextPage}>Next</button>
                </Col>
                </Row>}

                
            </Container>
            }
        </div>



///////////////////////////////////////////////////////////////////////////////////////////////////
        



    )
}