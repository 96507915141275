import {Container, Row, Col} from 'react-bootstrap'

export default function ArtistDebtResponse(props) {

    const numberFormat = (value) =>
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(value);

    if ( props.data.isError ){
        console.log("error:")
        console.log(props)
        return(
            <div className="response error">
                <b>{props.data.errorMessage}</b>
            </div>
            )
    }
    
    if ( props.data.date ) {
        console.log("data:")
        console.log(props.data)
    
        return(
            <div>
                <Container>
                    <Row>
                        <Col style={{display:'flex', justifyContent:'right'}}><h1>Artist debt as per {props.data.date}:</h1></Col>
                        <Col><h1>{numberFormat(props.data.total)}</h1></Col>
                    </Row>
                    <Row>
                        <Col style={{display:'flex', justifyContent:'right'}}><h1>Of which, inactive artist debt:</h1></Col>
                        <Col><h1>{numberFormat(props.data.inactiveTotal)}</h1></Col>
                    </Row>
                    <Row>
                        <Col style={{display:'flex', justifyContent:'right'}}><h1>Total:</h1></Col>
                        <Col><h1>{numberFormat(props.data.total - props.data.inactiveTotal)}</h1></Col>
                    </Row>
                </Container>
                <p></p>
                {!props.data.royaltyReportExists && <h1>OBS: Royalty reports have not been ingested yet for the selected month of the year</h1>}
            </div>
        )
    }
    else{
        console.log(props)
        return(<div className="spinner-border text-primary" role="status">
        <span className="sr-only"></span>
      </div>)
    }

}