import {Switch, Route } from 'react-router-dom'
import MossReport from './moss-report/moss-report'
import ValidateReports from './validate-reports/validate-reports'
import ArtificialStreamFees from './artificial-srteam-fees/artificial-srteam-fees'
import ArtistDebt from './artist-debt/artist-debt'
import Etl from './etl/etl'
import Landing from '../landing'
import ReportAutomation from './report-automation/report-automation'
import ProtectedRoute from './protected-route'
import PeriodicSummary from './periodic-summary/periodic-summary'
import SpotifyInvoice from './spotify-invoice/spotifyInvoice'


export default function Routing(){
    return(
        <Switch>
            <ProtectedRoute exact path="/" component={Landing} />
            <ProtectedRoute path="/etl" component={Etl} />
            <ProtectedRoute path="/validate" component={ValidateReports} />
            <ProtectedRoute path="/artificialstreamfees" component={ArtificialStreamFees} />
            <ProtectedRoute path="/moss" component={MossReport} />
            <ProtectedRoute path="/artistdebt" component={ArtistDebt} />
            <ProtectedRoute path="/report-automation" component={ReportAutomation} />
            <ProtectedRoute path="/periodic-summary" component={PeriodicSummary} />
            <ProtectedRoute path="/spotify-invoice-reports" component={SpotifyInvoice} />
        </Switch>
    )
}